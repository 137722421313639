/*
	Telemetry by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Quotes */

	.quotes > article:after {
		display: none;
	}

/* Header */

	.dropotron.level-0:before {
		display: none;
	}

/* Banner */

	#banner:before {
		display: none;
	}